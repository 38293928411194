// import { defineStore } from "pinia";
import { IUserDTO, UserRole } from "@/interfaces/User";

import { authService } from "@/services/AuthService";
import { IToken } from "@/interfaces/Token";
import { router } from "@/router";
import { defineStore } from "pinia";
import { apiService } from "@/services/ApiService";

const service = apiService;

interface HelperState {
  regionsState: any[];
  blocksState: any[];
  towTypesState: any[];
}

export const useHelperStore = defineStore("helper", {
  state: (): HelperState => ({
    regionsState: [],
    blocksState: [],
    towTypesState: [],
  }),
  getters: {
    blocks: (state) => state.blocksState,
    regions: (state) => state.regionsState,
    towTypes: (state) => state.towTypesState,
  },
  actions: {
    async loadRegions() {
      // TODO
      const response = await service.get<any[]>("getRegions");
      if (response) {
        this.regionsState = response;
      }
    },
    async loadBlocks() {
      // TODO
      const response = await service.get<any[]>("getBlockTypes");
      if (response) {
        this.blocksState = response;
      }
    },
    async loadTowTypes() {
      // TODO
      const response = await service.get<any[]>("getTowTypes");
      if (response) {
        this.towTypesState = response;
      }
    },
    getBlockNameById(id: number): string {
      const filtered = this.blocks.filter((e) => e.id === id);
      if (filtered && filtered.length > 0) {
        return filtered[0].name;
      }
      return "";
    },
    getRegionNameById(id: number): string {
      const filtered = this.regions.filter((e) => e.id === id);
      if (filtered && filtered.length > 0) {
        return filtered[0].name;
      }
      return "";
    },
    getTowTypeNameById(id: number): string {
      const filtered = this.towTypes.filter((e) => e.id === id);
      if (filtered && filtered.length > 0) {
        return filtered[0].name;
      }
      return "";
    },
  },
});
