import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MainPage from "@/pages/MainPage/MainPage.vue";
import { authGuard } from "@/services/AuthGuard";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta: { withoutAuth: true },
    component: MainPage,
  },
  {
    path: "/sign-in",
    name: "sign-in",
    meta: { withoutAuth: true },
    component: () => import("../pages/SignInPage.vue"),
  },
  {
    path: "/sign-up",
    name: "sign-up",
    meta: { withoutAuth: true },
    component: () => import("../pages/SignUpPage.vue"),
  },
  {
    path: "/sign-up-executor",
    name: "sign-up-executor",
    meta: { withoutAuth: true },
    component: () => import("../pages/SignUpExecutorPage.vue"),
  },
  {
    path: "/code",
    name: "code",
    meta: { withoutAuth: true },
    component: () => import("../pages/CodePage.vue"),
  },
  {
    path: "/order/:id",
    name: "order",
    props: true,
    component: () => import("../pages/Cabinet/OrderPage.vue"),
  },
  {
    path: "/cabinet",
    name: "cabinet",
    component: () => import("../pages/Cabinet/CabinetPage.vue"),
    children: [
      {
        path: "settings",
        name: "cabinet-settings",
        component: () => import("../pages/Cabinet/CabinetSettings.vue"),
      },

      {
        path: "balance",
        component: () => import("../pages/Cabinet/Balance/Balance.vue"),
      },
      {
        path: "create-order",
        name: "create-order",
        component: () => import("../pages/Cabinet/CreateOrder.vue"),
      },
      {
        path: "reviews",
        name: "reviews",
        component: () => import("../pages/Cabinet/ReviewsPage.vue"),
      },
      {
        path: "chats",
        name: "chats",
        component: () => import("../pages/Cabinet/ChatsPage.vue"),
      },
      {
        path: "executor-orders",
        name: "executor-orders",
        component: () =>
          import("../pages/Cabinet/OrdersPage/ExecutorOrdersPage.vue"),
        children: [
          {
            path: "",
            component: () =>
              import(
                "../pages/Cabinet/OrdersPage/components/InProgressOrders.vue"
              ),
          },
          {
            path: "completed",
            name: "completed",
            component: () =>
              import(
                "../pages/Cabinet/OrdersPage/components/ExecutorCompletedOrders.vue"
              ),
          },
          {
            path: "search",
            name: "search",
            component: () =>
              import(
                "../pages/Cabinet/OrdersPage/components/OrdersFromRegion.vue"
              ),
          },
        ],
      },
      {
        path: "orders",
        component: () => import("../pages/Cabinet/OrdersPage/OrdersPage.vue"),
        children: [
          {
            path: "",
            component: () =>
              import(
                "../pages/Cabinet/OrdersPage/components/PublishedOrders.vue"
              ),
          },
          {
            path: "completed",
            component: () =>
              import(
                "../pages/Cabinet/OrdersPage/components/CompletedOrders.vue"
              ),
          },
          {
            path: "executor/:executorId/:orderId",
            name: "executor",
            props: true,
            component: () =>
              import("../pages/Cabinet/ExecutorPage/ExecutorPage.vue"),
            children: [
              {
                path: "review",
                name: "executor.review",
                component: () =>
                  import(
                    "../pages/Cabinet/ExecutorPage/components/Reviews.vue"
                  ),
              },
              {
                path: "chat",
                component: () => import("../components/Chat.vue"),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/how-it-works",
    name: "how-it-works",
    meta: { withoutAuth: true },
    component: () => import("../pages/HowItWorksPage.vue"),
  },
  {
    path: "/why-us",
    name: "why-us",
    meta: { withoutAuth: true },
    component: () => import("../pages/WhyUsPage.vue"),
  },
  {
    path: "/why-us",
    name: "why-us",
    meta: { withoutAuth: true },
    component: () => import("../pages/WhyUsPage.vue"),
  },
  {
    path: "/help",
    name: "help",
    meta: { withoutAuth: true },
    component: () => import("../pages/HelpPage.vue"),
  },
  {
    path: "/wishes",
    name: "wishes",
    meta: { withoutAuth: true },
    component: () => import("../pages/WishesPage.vue"),
  },
  {
    path: "/offer",
    name: "offer",
    meta: { withoutAuth: true },
    component: () => import("../pages/OfferPage.vue"),
  },
  {
    path: "/user-agreement",
    name: "user-agreement",
    meta: { withoutAuth: true },
    component: () => import("../pages/UserAgreementPage.vue"),
  },
  {
    path: "/voditel-rules",
    name: "voditel-rules",
    meta: { withoutAuth: true },
    component: () => import("../pages/DriverRulesPage.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    meta: { withoutAuth: true },
    component: () => import("../pages/PrivacyPolicyPage.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    meta: { withoutAuth: true },
    component: () => import("../pages/NotFoundPage.vue"),
  },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.withoutAuth)) {
    next();
  } else {
    authGuard(to, from, next);
  }
});

export default router;
