import axios from "axios";
import { IUserDTO } from "@/interfaces/User";
import api from "./api";
import TokenService from "@/services/Token.service";

export class AuthService {
  constructor() {}

  private _isLogin: boolean = true;

  public async login(phone: string): Promise<boolean> {
    return api
      .post<IUserDTO>("https://sravni-evakuator.ru/api/auth", {
        phone,
      })
      .then((response) => {
        return true;
      });
  }

  public async loginWithCode(phone: string, code: string): Promise<IUserDTO> {
    return api
      .post<IUserDTO>("https://sravni-evakuator.ru/api/auth-confirm/" + code, {
        phone,
      })
      .then((response) => {
        if (response) {
          TokenService.setUser(response.data);
          return response.data;
        }
        return response;
      });
  }

  public registerClient(
    name: string,
    phone: string,
    email?: string
  ): Promise<IUserDTO> {
    return api
      .post<IUserDTO>("https://sravni-evakuator.ru/api/register-client", {
        name,
        phone,
        email,
      })
      .then((response) => response.data);
  }

  public registerExecutor(
    name: string,
    phone: string,
    passport: any[],
    tow: any[],
    photo?: any,
    email?: string,
    region?: any
  ): Promise<IUserDTO> {
    return axios
      .postForm<IUserDTO>("https://sravni-evakuator.ru/api/register-resolver", {
        name,
        phone,
        email,
        passport,
        tow,
        photo,
        region,
      })
      .then((response) => response.data);
  }
}

export const authService = new AuthService();
