import { defineStore } from "pinia";
import { IUserDTO, UserRole } from "@/interfaces/User";

import { authService } from "@/services/AuthService";
import { IToken } from "@/interfaces/Token";
// import { useRouter } from "vue-router";
import { router } from "@/router";

const service = authService;

interface AuthState {
  userState: any;
  temporaryPhoneState: string;
}

export const useAuthStore = defineStore({
  id: "auth",
  state: (): AuthState => ({
    userState: null,
    temporaryPhoneState: "",
  }),
  getters: {
    isLogged: (state) => state.userState !== null,
    user: (state) => state.userState,
    isLogin(): boolean {
      return !!this.userState;
    },
    getUser(): IUserDTO | null {
      const storageUser = localStorage.getItem("user");
      if (storageUser) {
        this.userState = JSON.parse(storageUser);
        return this.userState;
      }
      return null;
    },
    async getToken(): Promise<string> {
      const storageUser = localStorage.getItem("user");
      if (storageUser) {
        const user: IUserDTO = JSON.parse(storageUser);
        return user.token.access_token;
      }
      return "";
    },
    getTemporaryPhone(): string | null {
      return localStorage.getItem("temporaryPhone") || null;
    },
  },
  actions: {
    async login(phoneNumber: string) {
      const result = await service.login(phoneNumber);
      if (result) {
        this.setTemporaryPhone(phoneNumber);
        await router.push({ name: "code" });
      }
    },
    async loginWithCode(code: string) {
      const phoneNumber = this.getTemporaryPhone;
      if (!phoneNumber) {
        await router.push({ name: "sign-in" });
        return;
      }
      const result = await service.loginWithCode(phoneNumber, code);
      if (result) {
        this.setUser(result);
        this.setTemporaryPhone("");
        await router.push({ name: "cabinet-settings" });
      }
    },
    async logout() {
      this.userState = null;
      localStorage.removeItem("user");
      await router.push({ name: "sign-in" });
    },
    async registerClient(name: string, phoneNumber: string, email?: string) {
      const result = await service.registerClient(name, phoneNumber, email);
      if (result) {
        this.setUser(result);
        await router.push({ name: "create-order" });
      }
    },
    async registerExecutor(
      name: string,
      phone: string,
      passport: any[],
      tow: any[],
      photo?: any,
      email?: string,
      region?: any
    ) {
      const result = await service.registerExecutor(
        name,
        phone,
        passport,
        tow,
        photo,
        email,
        region
      );
      if (result) {
        this.setUser(result);
        await router.push({ name: "cabinet-settings" });
      }
    },
    setUser(user: IUserDTO) {
      if (this.userState) {
        this.userState = { ...this.userState, ...user };
      } else {
        this.userState = user;
      }
      localStorage.setItem("user", JSON.stringify(this.userState));
    },
    setTemporaryPhone(phone: string) {
      // this.temporaryPhoneState = phone;
      localStorage.setItem("temporaryPhone", phone);
    },
  },
});
