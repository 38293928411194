import { IReview } from "@/interfaces/Review";
import { IRegionDTO } from "@/interfaces/Region";
import { IToken } from "@/interfaces/Token";

export enum UserRole {
  USER = "Клиент",
  EXECUTOR = "Владелец эвакуатора",
  ADMIN = "admin",
}

export interface IUser {
  name: string;
  id: string;
  role: UserRole;
  settings: IUserSettings;
}

export interface IUserSettings {
  email: string;
  phone: string;
  rating?: number;
  avatarPhotoUrl?: string;
  passportPhotoUrl?: string;
  carPhotosUrls?: string[];
  ordersCount?: number;
  responsePrice?: number;
}

export interface IUserTypeDTO {
  name: string;
  slug: string;
}

export interface IUserDTO {
  name: string;
  email: string;
  phone: string;
  photo: string;
  passport: string[];
  tow: string[];
  balance: number;
  rating: number;
  region: IRegionDTO;
  user_type: IUserTypeDTO;
  token: IToken;
}

export const UserMock: IUser = {
  name: "Виктор",
  id: "01",
  role: UserRole.USER,
  settings: {
    email: "test@gmail.com",
    phone: "+3751234567",
    avatarPhotoUrl: "https://i.pravatar.cc/300",
  },
};

export const UserExecutorMock: IUser = {
  name: "Андрей",
  id: "02",
  role: UserRole.EXECUTOR,
  settings: {
    email: "test@gmail.com",
    phone: "+3751234567",
    rating: 5,
    avatarPhotoUrl: "https://i.pravatar.cc/300",
    passportPhotoUrl: "https://i.pravatar.cc/300",
    carPhotosUrls: [
      "https://i.pravatar.cc/300",
      "https://i.pravatar.cc/300",
      "https://i.pravatar.cc/300",
    ],
    ordersCount: 32,
    responsePrice: 50,
  },
};
