import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import router from "./router";
import { createPinia } from "pinia";
import "@/services/api";
import { createHead } from "@vueuse/head";
import { VueReCaptcha } from "vue-recaptcha-v3";
// import "./assets/main.css";

const head = createHead();
const pinia = createPinia();

import SetupInterceptors from "@/services/setupInterceptors";

SetupInterceptors();

createApp(App)
  .use(VueReCaptcha, {
    siteKey: "6LfzkMApAAAAAPFbKtfj0mpxXqBZwowIXM4tE129",
    loaderOptions: {},
  })
  .use(pinia)
  .use(head)
  .use(router)
  .use(ElementPlus)
  .mount("#app");
