import { defineStore } from "pinia";

export const useLoadingStore = defineStore({
  id: "loading",
  state: () => ({
    isLoading: false,
  }),
  getters: {
    loading(): boolean {
      return this.isLoading;
    },
  },
  actions: {
    setLoading(state: boolean): void {
      this.isLoading = state;
    },
  },
});
