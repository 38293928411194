import axiosInstance from "./api";
import TokenService from "./Token.service";
import { useAuthStore } from "@/stores/auth.store";
import axios, { AxiosError } from "axios";
import { useRouter } from "vue-router";
import { ElMessage, ElNotification } from "element-plus";

const setup = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  localStorage.setItem("isTokenRefreshing", "");
  const isRefreshing = () => {
    return !!localStorage.getItem("isTokenRefreshing");
  };
  let failedQueue: {
    reject: (value?: unknown) => void;
    resolve: (value?: unknown) => void;
  }[] = [];

  const processQueue = (error: Error | null, token: null | string) => {
    for (const prom of failedQueue) {
      if (error) prom.reject(error);
      else prom.resolve(token);
    }
    failedQueue = [];
  };

  axiosInstance.interceptors.response.use(
    (response) => {
      if (!response) {
        throw new Error("Response is not provided");
      }
      return response;
    },
    async (responseError: AxiosError & { config: { _retry: boolean } }) => {
      const originalRequest = responseError.config;
      const originalResponse = responseError.response;
      const status = originalResponse ? originalResponse.status : NaN;
      const authStore = useAuthStore();

      if (status === 401) {
        if (isRefreshing()) {
          if (originalRequest.url == "/refresh") {
            await authStore.logout();
            return;
          }
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        if (!originalRequest._retry) {
          originalRequest._retry = true;
          localStorage.setItem("isTokenRefreshing", "true");
          let isError = false;
          try {
            const token = TokenService.getLocalAccessToken();
            const response = await axios.post(
              "https://sravni-evakuator.ru/api/refresh",
              {
                token: token,
              }
            );
            const accessToken = response.data.token.access_token;
            TokenService.updateLocalAccessToken(accessToken);
            originalRequest.headers["Authorization"] = "Bearer " + accessToken;
            processQueue(null, accessToken);
          } catch (e: any) {
            isError = true;
            processQueue(e, null);
            await authStore.logout();
          }
          localStorage.setItem("isTokenRefreshing", "");
          if (!isError) {
            return axiosInstance(originalRequest);
          }
        }
      } else {
        let message: any = responseError.message;
        if (responseError.response?.data) {
          const errorData: any = responseError.response.data;
          message = errorData.message;
        }
        ElMessage({
          message: message || "Что-то пошло не так",
          type: "error",
        });
        return null;
      }

      throw responseError;
    }
  );
};

export default setup;
