import type { NavigationGuard } from "vue-router";
import { useAuthStore } from "@/stores/auth.store";

export const authGuard: NavigationGuard = async (to, from, next) => {
  const authStore = useAuthStore();
  if (authStore.isLogin) {
    next();
  } else {
    let nextPath = "/sign-in";
    if (to.name == "create-order") {
      nextPath = "/sign-up";
    }
    next(nextPath);
  }
};
