<template>
  <div class="close-button">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5.00003L15.6452 15.6452"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 15.6452L15.6452 5.00001"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";
.close-button {
  cursor: pointer;

  &:hover {
    svg {
      path {
        stroke: $color-orange;
      }
    }
  }
}
</style>
