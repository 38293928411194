import axios from "axios";
import { ElNotification } from "element-plus";
import api from "./api";

class ApiService {
  private readonly BASE_URL: string = "https://sravni-evakuator.ru/api/";

  async post<T>(url: string, data: any): Promise<T> {
    return new Promise((resolve, reject) => {
      try {
        api.post(this.BASE_URL + url, data).then((response) => {
          if (response) {
            resolve(response.data as T);
          } else {
            reject(false);
          }
        });
      } catch (e) {}
    });
  }

  async postForm<T>(url: string, data: any): Promise<T> {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return new Promise((resolve, reject) => {
      try {
        api.post(this.BASE_URL + url, data, config).then((response) => {
          if (response) {
            resolve(response.data as T);
          } else {
            reject(false);
          }
        });
      } catch (e) {}
    });
  }

  async get<T>(url: string): Promise<T> {
    return new Promise((resolve, reject) => {
      try {
        api.get(this.BASE_URL + url).then((response) => {
          if (response) {
            resolve(response.data as T);
          } else {
            reject(false);
          }
        });
      } catch (e) {}
    });
  }
}

export const apiService = new ApiService();
