import {
  IUser,
  IUserSettings,
  UserExecutorMock,
  UserMock,
  UserRole,
} from "@/interfaces/User";
import { ReviewMock } from "@/interfaces/Review";
import { getRandomId } from "@/utils/utils";

export class UserService {
  constructor() {}

  private _userSettings: IUserSettings = {
    rating: 4,
    email: "test@gmail.com",
    phone: "375447507435",
    avatarPhotoUrl: "https://i.pravatar.cc/300",
    passportPhotoUrl: "https://i.pravatar.cc/300",
    carPhotosUrls: [
      "https://i.pravatar.cc/300",
      "https://i.pravatar.cc/300",
      "https://i.pravatar.cc/300",
    ],
    ordersCount: 32,
    responsePrice: 50,
  };

  private _user: IUser = UserMock;

  public get user(): IUser {
    return this._user;
  }

  public set user(user: IUser) {
    this._user = user;
  }

  public get userSettings(): IUserSettings {
    return this._userSettings;
  }
}
