class TokenService {
  getLocalRefreshToken() {
    const q: string | null = localStorage.getItem("user");
    if (q) {
      const user = JSON.parse(q);
      return user.token.access_token;
    }
    return "";
  }

  getLocalAccessToken() {
    const q: string | null = localStorage.getItem("user");
    if (q) {
      const user = JSON.parse(q);
      return user.token.access_token;
    }
    return "";
  }

  updateLocalAccessToken(token: string) {
    const q: string | null = localStorage.getItem("user");
    if (q) {
      const user = JSON.parse(q);
      user.token.access_token = token;
      localStorage.setItem("user", JSON.stringify(user));
    }
  }

  getUser() {
    const q: string | null = localStorage.getItem("user");
    if (q) {
      return JSON.parse(q);
    }
  }

  setUser(user: any) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem("user");
  }
}

export default new TokenService();
